(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/router/assets/javascripts/tabs-loader.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/router/assets/javascripts/tabs-loader.js');

'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  Loader
} = svs.components.ReactLoader;
const {
  Button
} = svs.ui;
const {
  withGlobalEvent
} = svs.utils;
function getOffset(element) {
  const box = element.getBoundingClientRect();
  return {
    left: box.left + (window.pageXOffset - document.documentElement.clientLeft),
    top: box.top + (window.pageYOffset - document.documentElement.clientTop)
  };
}
class TabsLoader extends React.Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "loadingContainer", React.createRef());
    _defineProperty(this, "onGlobalResize", () => {
      this.fixContainerHeight();
    });
    _defineProperty(this, "fixContainerHeight", () => {
      if (this.loadingContainer.current) {
        const offset = getOffset(this.loadingContainer.current);
        const desiredHeight = window.innerHeight - offset.top;
        this.loadingContainer.current.style.height = "".concat(desiredHeight, "px");
      }
    });
  }
  componentDidMount() {
    this.fixContainerHeight();
  }
  componentDidUpdate(prevProps) {
    if (['error', 'timeout'].some(prop => this.props[prop] !== prevProps[prop])) {
      this.fixContainerHeight();
    }
  }
  render() {
    const {
      error,
      timeout,
      retry
    } = this.props;
    if (error) {
      return React.createElement("div", {
        className: "f-center",
        "data-testid": "qa-marketplace-tabs-loader-errored"
      }, React.createElement("h1", null, "N\xE5gonting gick fel"), React.createElement("p", null, "Sidan du f\xF6rs\xF6kte n\xE5 kunde inte h\xE4mtas."), React.createElement(Button, {
        className: "qa-loader-retry",
        "data-testid": "qa-marketplace-tabs-loader-errored-button",
        focus: true,
        onClick: retry
      }, "F\xF6rs\xF6k igen"));
    }
    return React.createElement("div", {
      className: "marketplace-tabs-loader-container",
      "data-testid": "qa-marketplace-tabs-loader",
      ref: this.loadingContainer
    }, timeout ? React.createElement("div", {
      className: "marketplace-tabs-loader-container",
      ref: this.loadingContainer
    }, React.createElement("div", null, React.createElement("h1", null, "Det verkar ta l\xE5ng tid"), React.createElement("p", {
      className: "margin-bottom-2"
    }, "Vi forts\xE4tter att f\xF6rs\xF6ka h\xE4mta sidan."), React.createElement(Loader, {
      inverted: true
    }))) : React.createElement(Loader, {
      inverted: true
    }));
  }
}
_defineProperty(TabsLoader, "defaultProps", {
  error: null,
  retry: () => null,
  timeout: false
});
setGlobal('svs.marketplace.components.router.TabsLoader', withGlobalEvent('resize')(TabsLoader));

 })(window);