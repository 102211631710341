(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/router/assets/javascripts/marketplace-retail.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/router/assets/javascripts/marketplace-retail.js');

'use strict';

const {
  useEffect
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  DocumentTitle
} = svs.components.lbUtils;
const {
  Link,
  Route
} = ReactRouterDOM;
const {
  ReactButtonGroup: ButtonGroup,
  Button
} = svs.ui;
const {
  selectors,
  teams
} = svs.components.marketplaceData;
const {
  groupTypeNames
} = svs.components.marketplaceData.constants;
const {
  trackEvent
} = svs.components.analytics;
const {
  chatData
} = svs.components.chat;
const {
  Loader
} = svs.components.ReactLoader;
const {
  lazy
} = svs.components.lbUtils.lazy;
const {
  TabsLoader
} = svs.marketplace.components.router;
const {
  RedirectToTeamSlugContainer
} = svs.components.marketplace.redirectToSlug;
const RetailViewMaster = lazy('react:retail-view', () => svs.marketplace.components.retailView.RetailViewMaster, {
  fallback: TabsLoader
});
const BrowseGames = lazy('/cl/marketplace/react:browse-games', () => svs.components.marketplace.browseGames.BrowseGamesV2, {
  fallback: TabsLoader
});
const RetailListPage = lazy('react:retail-list-page', () => svs.marketplace.components.retailListPage.RetailListPage, {
  fallback: TabsLoader
});
const RetailListOnboarding = lazy('react:retail-list-page', () => svs.marketplace.components.retailListPage.RetailListOnboarding, {
  fallback: TabsLoader
});
const retailTeamBaseRoute = '/ombudslag/:groupId([0-9]+):retailerPathName([-\\w]*)?';
const withMenu = Component => props => {
  const {
    location
  } = props;
  const options = [{
    key: 'list-retailers',
    label: 'Dina butiker',
    path: '/ombudslag'
  }, {
    key: 'find-retailers',
    label: 'Hitta butiker',
    path: '/hitta-andelsspel'
  }];
  return React.createElement("div", {
    className: "marketplace-retail marketplace-retail-background"
  }, React.createElement(DocumentTitle, {
    title: "Spela Tillsammans"
  }), React.createElement("div", {
    className: "marketplace-retail-menu"
  }, React.createElement(ButtonGroup, {
    block: true,
    className: "marketplace-retail-button-group"
  }, options.map(_ref => {
    let {
      key,
      label,
      path
    } = _ref;
    const isActive = path === location.pathname;
    return (
      React.createElement(Button, {
        className: "qa-".concat(key, "-button"),
        inverted: isActive,
        key: path,
        transparent: !isActive
      }, React.createElement(Link, {
        className: "qa-".concat(key, "-link"),
        onClick: () => {
          trackEvent({
            category: 'Tillsammans',
            action: 'btn_interactions',
            opt_label: label.toUpperCase()
          });
        },
        to: path
      }, label))
    );
  }))), React.createElement(Component, null));
};
const BrowseGamesWithMenu = withMenu(BrowseGames);
const RetailStartPageMaster = () => {
  const dispatch = useDispatch();
  const groupTypeFilter = groupTypeNames.RETAILER;
  const offset = useSelector(selectors.teams.selectRetailerTeamsOffset);
  const fetchNextRetailersAndTeams = () => dispatch(teams.actions.fetchNextRetailersAndTeamsAction({
    groupTypeFilter,
    offset
  }));
  const getUnread = () => dispatch(chatData.actions.getUnread());
  const loggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  useEffect(() => {
    if (loggedIn) {
      getUnread();
      fetchNextRetailersAndTeams();
    }
  }, []);
  const hasRetailers = useSelector(selectors.teams.getRetailers).filter(retailer => retailer.isFollowing).length > 0;
  const loading = useSelector(state => state.Teams.loading || !state.Teams.hasFetched && loggedIn);
  if (loading && !hasRetailers) {
    return React.createElement("div", {
      className: "marketplace-full-height marketplace-center-container",
      "data-testid": "qa-mkp-welcome-loading"
    }, React.createElement(Loader, {
      inverted: true
    }));
  }
  if (hasRetailers) {
    return React.createElement(RetailListPage, null);
  }
  return React.createElement(RetailListOnboarding, null);
};
const MarketplaceRetail = () => {
  const switchedRoutes = [{
    component: withMenu(RetailStartPageMaster),
    exact: true,
    path: '/ombudslag'
  }, {
    component: RetailViewMaster,
    path: retailTeamBaseRoute,
    exact: false
  }, {
    component: BrowseGamesWithMenu,
    exact: false,
    label: 'Sök andelsspel',
    path: '/hitta-andelsspel'
  }];
  return React.createElement(React.Fragment, null, React.createElement(Route, {
    component: RedirectToTeamSlugContainer,
    exact: true,
    key: "team_redirect",
    path: "".concat(retailTeamBaseRoute, ":additionalPath(/.*?)*")
  }), switchedRoutes.map(_ref2 => {
    let {
      path,
      exact,
      component,
      render
    } = _ref2;
    return React.createElement(Route, {
      component: component,
      exact: exact,
      key: path,
      path: path,
      render: render
    });
  }));
};
setGlobal('svs.marketplace.components.router.MarketplaceRetail', MarketplaceRetail);

 })(window);