(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/router/assets/javascripts/marketplace-join-team.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/router/assets/javascripts/marketplace-join-team.js');

'use strict';

const {
  Route
} = ReactRouterDOM;
const {
  DocumentTitle
} = svs.components.lbUtils;
const {
  JoinTeam
} = svs.marketplace.components.joinTeam;
const joinPath = '/bli-medlem/:invitationToken?';
const MarketplaceJoinTeam = () => React.createElement(React.Fragment, null, React.createElement(DocumentTitle, {
  title: "Spela tillsammans"
}), React.createElement(Route, {
  component: JoinTeam,
  exact: true,
  path: joinPath
}));
setGlobal('svs.marketplace.components.router.MarketplaceJoinTeam', MarketplaceJoinTeam);

 })(window);