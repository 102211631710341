(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/router/assets/javascripts/marketplace-team.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/router/assets/javascripts/marketplace-team.js');

'use strict';

const {
  useEffect
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  Route
} = ReactRouterDOM;
const {
  selectors,
  teams
} = svs.components.marketplaceData;
const {
  groupTypeNames
} = svs.components.marketplaceData.constants;
const {
  chatData
} = svs.components.chat;
const {
  Loader
} = svs.components.ReactLoader;
const {
  lazy
} = svs.components.lbUtils.lazy;
const {
  TabsLoader
} = svs.marketplace.components.router;
const {
  RedirectToTeamSlugContainer
} = svs.components.marketplace.redirectToSlug;
const TeamDetailMaster = lazy('react:team-detail', () => svs.marketplace.components.teamDetail.TeamDetailMaster, {
  fallback: TabsLoader
});
const TeamListPage = lazy('react:team-list-page', () => svs.marketplace.components.teamListPage.TeamListPage, {
  fallback: TabsLoader
});
const TeamListOnboarding = lazy('react:team-list-onboarding', () => svs.marketplace.components.teamListPage.TeamListOnboarding, {
  fallback: TabsLoader
});
const teamBaseRoute = '/lag/:groupId([0-9]+):teamPathName([-\\w]*)?';
const MarketplaceTeam = () => {
  const dispatch = useDispatch();
  const loggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  const groupTypeFilter = groupTypeNames.PRIVATE;
  const offset = useSelector(selectors.teams.selectCustomerTeamsOffset);
  const fetchNextRetailersAndTeams = () => dispatch(teams.actions.fetchNextRetailersAndTeamsAction({
    groupTypeFilter,
    offset
  }));
  const getUnread = () => dispatch(chatData.actions.getUnread());
  const hasTeams = useSelector(selectors.teams.getTeams).length > 0;
  const loading = useSelector(state => state.Teams.loading || !state.Teams.hasFetched && loggedIn);
  useEffect(() => {
    if (loggedIn) {
      getUnread();
      fetchNextRetailersAndTeams();
    }
  }, []);
  const switchedRoutes = [{
    component: TeamDetailMaster,
    path: teamBaseRoute
  }, {
    component: hasTeams ? TeamListPage : TeamListOnboarding,
    exact: true,
    path: '/lag'
  }];
  if (loading && !hasTeams) {
    return React.createElement("div", {
      className: "marketplace-full-height marketplace-center-container",
      "data-testid": "qa-mkp-welcome-loading"
    }, React.createElement(Loader, {
      inverted: true
    }));
  }
  return React.createElement("div", {
    className: "marketplace-team"
  }, React.createElement(Route, {
    component: RedirectToTeamSlugContainer,
    exact: true,
    key: "team_redirect",
    path: "".concat(teamBaseRoute, ":additionalPath(/.*?)*")
  }), switchedRoutes.map(_ref => {
    let {
      path,
      exact,
      component,
      render
    } = _ref;
    return React.createElement(Route, {
      component: component,
      exact: exact,
      key: path,
      path: path,
      render: render
    });
  }));
};
setGlobal('svs.marketplace.components.router.MarketplaceTeam', MarketplaceTeam);

 })(window);